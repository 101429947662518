import React from 'react';
import { Link } from 'react-router-dom';
import GetTheApp from '../header/getTheApp';

class HomeSlider extends React.Component {
  render() {
    return (
      <div className="jumbotron jumbotron-fluid text-center sliderBackground">
        <div className="container">
          <h3
            style={{ color: 'white', fontSize: '2rem' }}
            className="text-center font-weight-bold"
          >
            Where Computer Enthusiasts Buy and Sell Parts
            <br />
          </h3>
          {/* <h5 className='display-4 '>
            <b>Unused Parts</b>
          </h5> */}
          <div className="lead mb-3">
            Try us out for FREE - We offer lower fees than eBay!
          </div>

          <p>
            <Link
              className="btn text-primary btn-light btn-lg"
              to="/howitworks"
            >
              How it Works
            </Link>
          </p>

          <p className="mb-3">
            <button
              className="btn btn-primary btn-lg sellGearBtn mb-0"
              onClick={() => this.props.history.push('/items/new')}
            >
              Sell Your Hardware
            </button>
          </p>
          <GetTheApp height={40} width="auto" row />
        </div>
      </div>
    );
  }
}
export default HomeSlider;
