import React from 'react';
import loadable from '@loadable/component';

import HomeSlider from '../components/home/homeSlider';
// import RecentItems from '../components/home/recentItems';
// import RecentSoldItems from '../components/home/recentSold';
// import HomeCategories from '../components/home/homeCategories';
// import Hypetestimonials from '../components/testimonials/Hypetestimonials';
// import Features from '../components/Features';

const HomeCategories = loadable(() =>
  import('../components/home/homeCategories')
);
const RecentItems = loadable(() => import('../components/home/recentItems'));
const RecentSoldItems = loadable(() => import('../components/home/recentSold'));
const Hypetestimonials = loadable(() =>
  import('../components/testimonials/Hypetestimonials')
);
const Features = loadable(() => import('../components/Features'));
// const HomeCategories = loadable(() =>
//   import('../components/home/homeCategories')
// );

const IndexContainer = (props) => (
  <>
    <HomeSlider {...props} />
    <section className="section section-featured-categories mb-4 pt-5 pb-sm-0 pb-5">
      <div className="container featured_categories">
        <HomeCategories fallback={<div>Loading...</div>} />
      </div>
    </section>
    <section className="section section-products mb-4">
      <div className="container">
        <RecentItems fallback={<div>Loading...</div>} />
      </div>
    </section>
    <section>
      <div>
        <RecentSoldItems fallback={<div>Loading...</div>} />
      </div>
    </section>
    <section className="bg-custom-light section section-testimonials p-4 pb-5">
      <div className="container">
        <Hypetestimonials fallback={<div>Loading...</div>} />
      </div>
    </section>
    <section className="bg-custom-light section section-testimonials p-4 pb-5">
      <div>
        <Features fallback={<div>Loading...</div>} />
      </div>
    </section>
  </>
);
export default IndexContainer;
